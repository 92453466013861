import React from 'react';
import { Row, Spin } from 'antd';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import './styles.scss';

interface CustomLoaderProps {
  size?: number,
}

const defaultProps: {
    size?: number | null
} = {
  size: 50,
};

const CustomLoader: React.FC<CustomLoaderProps> = ({ size }) => (
  <Row justify="center" align="middle" className="custom-loader-container">
    <Spin indicator={(
      <Loading3QuartersOutlined
        style={{
          fontSize: size,
          color: '#141E42',
        }}
        spin
      />
    )}
    />
  </Row>
);

CustomLoader.defaultProps = defaultProps;

export default CustomLoader;
