import React, { useEffect, useState } from 'react';
import {
  Navigate, Route, Routes, useLocation, useNavigate, useSearchParams,
} from 'react-router-dom';
import { loader } from 'graphql.macro';
import { useDispatch } from 'react-redux';
import { useApolloClient, useMutation } from '@apollo/client';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { useTranslation } from 'react-i18next';
import { AUTH_TOKEN } from '../../utils/constants';
import routesList from '../../routes/routesList';
import RouteElement from '../../routes/RouteElement';
import routesDictionary from '../../routes/routesDictionary';
import { AppDispatch } from '../../store';
import { startSetUserData } from '../../store/thunks/auth';
import Navbar from '../../components/Navbar';
import CustomLoader from '../../components/CustomLoader';
import { smallBreakpoints } from '../../components/Sidebar/constants';
import { hideBanner } from '../../store/slices/ui';

const userInfoQuery = loader('./userInfoQuery.gql');
const verifyTokenRequest = loader('./verifyToken.gql');

const Layout = () => {
  const { t } = useTranslation();
  const [login, setLogin] = useState<string | null | undefined>();
  const token = localStorage.getItem(AUTH_TOKEN);
  const [verifyTokenMutation] = useMutation(verifyTokenRequest);
  const location = useLocation();

  const client = useApolloClient();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');

  const handleRedirectLogin = () => {
    navigate(routesDictionary.login, { replace: true });
    setLoading(false);
  };

  const screens = useBreakpoint();
  const [breakpoint, setBreakpoint] = useState<string>();

  useEffect(() => {
    Object.entries(screens)
      .filter((screen) => screen[1])
      .map((screen) => setBreakpoint(screen[0]));
  }, [screens]);

  useEffect(() => {
    dispatch(hideBanner());
  }, [location]);

  useEffect(() => {
    if (token) {
      setLoading(true);
      verifyTokenMutation({
        variables: { token },
      }).then(({ data: verifyTokenData }) => {
        const { verifyToken } = verifyTokenData;
        if (verifyToken) {
          client.query(
            {
              query: userInfoQuery,
              fetchPolicy: 'no-cache',
            },
          ).then(({ data }) => {
            const { me } = data;
            dispatch(startSetUserData({
              firstName: me.firstName,
              lastName: me.lastName,
              groups: me.groups.map((group: any) => group.name),
              graduateUnitId: me.unitmanager?.graduateUnit?.id,
              graduateUnitName: me.unitmanager?.graduateUnit?.name,
              userType: me.groups[0]?.name,
              profilePicture: me.profilePicture,
              professorId: me?.professor?.id,
              studentId: me?.student?.id,
            }))
              .then(() => {
                setLogin(token);
                setLoading(false);
              });
          }).catch(() => handleRedirectLogin());
        } else handleRedirectLogin();
      }).catch(() => handleRedirectLogin());
    } else {
      if (!code) {
        handleRedirectLogin();
      }
      setLoading(false);
    }
  }, [login]);

  if (loading || !breakpoint) {
    return (
      <div className="vh-100 w-100">
        <CustomLoader />
      </div>
    );
  }

  return (
    <Navbar
      login={login}
      displayDrawer={smallBreakpoints.includes(breakpoint)}
      setLogin={setLogin}
    >
      <div>
        <Routes>
          {routesList(t).map((route) => (
            <Route
              path={route.path}
              key={route.path}
              element={(
                <RouteElement
                  key={route.path}
                  module={route.module}
                  component={route.component}
                  path={route.path}
                  title={route.title}
                  type={route.type}
                  login={login}
                  setLogin={setLogin}
                />
            )}
            />
          ))}
          <Route
            path="*"
            element={<Navigate replace to={routesDictionary.allOperatingPlan.route} />}
          />
        </Routes>
      </div>
    </Navbar>
  );
};

export default Layout;
