import { createSlice } from '@reduxjs/toolkit';

interface authState {
  token: string | null,
  firstName: string,
  lastName: string,
  groups: string[],
  graduateUnitId: string,
  graduateUnitName: string,
  userType: string,
  profilePicture: string
  professorId: string | null
  studentId: string | null
  operatingPlanProcess: string | null,
}

const initialState: authState = {
  token: '',
  firstName: '',
  lastName: '',
  groups: [],
  graduateUnitId: '',
  graduateUnitName: '',
  userType: '',
  profilePicture: '',
  professorId: '',
  studentId: '',
  operatingPlanProcess: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, { payload }) => {
      state.token = payload.token;
    },
    logout: (state) => {
      state.token = null;
    },

    setUserData: (state, { payload }) => {
      state.firstName = payload.firstName;
      state.lastName = payload.lastName;
      state.groups = payload.groups;
      state.graduateUnitId = payload.graduateUnitId;
      state.graduateUnitName = payload.graduateUnitName;
      state.userType = payload.userType;
      state.profilePicture = payload.profilePicture;
      state.studentId = payload.studentId;
      state.professorId = payload.professorId;
    },
    changeUserType: (state, { payload }) => {
      state.userType = payload.userType;
    },
    setOperatingPlanProcess: (state, { payload }) => {
      state.operatingPlanProcess = payload.operatingPlanProcess;
    },
  },
});

export const {
  login, logout, setUserData, changeUserType, setOperatingPlanProcess,
} = authSlice.actions;
