import { ApolloClient } from '@apollo/client';
import {
  changeUserType, login, logout, setOperatingPlanProcess, setUserData,
} from '../../slices/auth';
import { AUTH_TOKEN } from '../../../utils/constants';

export const startGoogleSignIn = (data: any) => async (
  // eslint-disable-next-line no-unused-vars
  dispatch: (arg0: any) => void,
) => {
  localStorage.setItem(AUTH_TOKEN, data.login.token);
  dispatch(login({ token: data.login.token }));
};

export const startLogin = (client: ApolloClient<any>, token: string) => async (
  // eslint-disable-next-line no-unused-vars
  dispatch: (arg0: any) => void,
) => {
  localStorage.setItem(AUTH_TOKEN, token);
  dispatch(login({ token }));
};

// eslint-disable-next-line no-unused-vars
export const startLogout = () => async (dispatch: (arg0: any) => void) => {
  localStorage.clear();
  dispatch(logout());
};

// eslint-disable-next-line no-unused-vars
export const startSetUserData = (userData: any) => async (dispatch: (arg0: any) => void) => {
  dispatch(setUserData(userData));
  return userData;
};
// eslint-disable-next-line no-unused-vars
export const startChangeUserType = (userType: string) => async (dispatch: (arg0: any) => void) => {
  dispatch(changeUserType({ userType }));
};

export const startsetOperatingPlanProcess = (
  operatingPlanProcess: string,
// eslint-disable-next-line no-unused-vars
) => async (dispatch: (arg0: any) => void) => {
  dispatch(setOperatingPlanProcess({ operatingPlanProcess }));
};
