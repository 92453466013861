import {
  Avatar,
  Col, Dropdown, Layout, Menu, Row,
} from 'antd';
import React, { useState } from 'react';
import { Content, Footer, Header } from 'antd/es/layout/layout';
import { AiOutlineUser } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { RiArrowUpSLine, RiLogoutBoxLine, RiMenuLine } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import Sidebar from '../Sidebar';
import { ReactComponent as FlagUsa } from '../../media/icons/flag_usa.svg';
import { ReactComponent as FlagSpain } from '../../media/icons/flag_spain.svg';
import './styles.scss';
import { getMediaLink } from '../../utils/tools';
import { AppDispatch } from '../../store';
import { startLogout } from '../../store/thunks/auth';
import CustomBreadCrumb from '../CustomBreadCrumb';
import CustomFooter from '../CustomFooter';
import Banner from '../Banner';

interface User {
  firstName: string,
  lastName: string,
  userType: string
  profilePicture: string,
}
interface NavbarProps {
  children: React.ReactElement
  login: string | null | undefined
  displayDrawer: boolean,
  setLogin: any
}

const languagesMenu = (i18n: any, translator: any, setCollapsedMenu: any) => (
  <Menu className="menu-container">
    <Menu.Item
      key="setting"
      className="sub-menu"
      icon={<FlagUsa className="header__icon" />}
      onClick={() => {
        i18n.changeLanguage('EN');
        setCollapsedMenu((collapsed: boolean) => !collapsed);
      }}
    >
      <span className="header__text header__text--dropdown ">
        {translator('english')}
      </span>
    </Menu.Item>

    <Menu.Item
      key="menu-item"
      className="sub-menu"
      icon={<FlagSpain className="header__icon" />}
      onClick={() => {
        i18n.changeLanguage('ES');
        setCollapsedMenu((collapsed: boolean) => !collapsed);
      }}
    >
      <span className="header__text header__text--dropdown">
        {translator('spanish')}
      </span>
    </Menu.Item>
  </Menu>
);

const LanguagesMenu = ({ translator, i18n }: any) => {
  const { language } = i18n;
  const [collapsedMenu, setCollapsedMenu] = useState(false);

  return (
    <Dropdown
      onOpenChange={(e) => setCollapsedMenu(e)}
      overlay={languagesMenu(i18n, translator, setCollapsedMenu)}
      open={collapsedMenu}
      arrow
      trigger={['click']}
    >
      <Row
        onClick={() => setCollapsedMenu(!collapsedMenu)}
        style={{ cursor: 'pointer' }}
        align="middle"
      >
        <Col>
          <Row align="middle">
            {language === 'ES' ? (
              <>
                <FlagSpain className="header__icon" />
                <span className="header__text">
                  {translator('spanish')}
                </span>
              </>
            ) : (
              <>
                <FlagUsa className="header__icon" />
                <span className="header__text">
                  {translator('english') }
                </span>
              </>
            )}
          </Row>
        </Col>
        <RiArrowUpSLine
          className={`navbar__arrow-icon
             ${collapsedMenu
            ? 'navbar__arrow-icon--down'
            : 'navbar__arrow-icon--up'}`}
        />
      </Row>

    </Dropdown>
  );
};

const Navbar:React.FC<NavbarProps> = ({
  children, login, displayDrawer, setLogin,
}) => {
  const [collapsedMenu, setCollapsedMenu] = useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const userData = useSelector((state: {auth: User}) => state.auth);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { breadcrumb, showBanner } = useSelector((state: {ui: any}) => state.ui);

  const headerMenu = (
    <Row
      className="navbar"
      align="middle"
      onClick={() => setCollapsedMenu(!collapsedMenu)}
    >
      <Row className="navbar__avatar-icon">
        <Avatar
          className="navbar__avatar-icon--border"
          size="large"
          {...userData.profilePicture
            ? { src: getMediaLink(userData.profilePicture) }
            : { icon: <AiOutlineUser /> }}
        />
      </Row>
      <Row justify="end" align="middle" style={{ maxWidth: 140 }}>
        <Col span={24}>
          <span className="navbar__user-name">
            {`${userData.firstName} ${userData.lastName}`}
          </span>
        </Col>
        <Col span={24}>
          <span className="navbar__type-user">
            {userData.userType || '-'}
          </span>
        </Col>
      </Row>
      <Row>
        <RiArrowUpSLine
          className={`navbar__arrow-icon
             ${collapsedMenu
            ? 'navbar__arrow-icon--down'
            : 'navbar__arrow-icon--up'}`}
        />
      </Row>
    </Row>
  );

  const menu = (
    <Menu className="menu-container">
      {/* <Menu.Item */}
      {/*  key="setting" */}
      {/*  className="sub-menu" */}
      {/*  icon={<AiOutlineUser />} */}
      {/* > */}
      {/*  <span className="dropdown-header"> */}
      {/*    Profile */}
      {/*  </span> */}
      {/* </Menu.Item> */}
      <Menu.Item
        key="logout"
        className="sub-menu"
        icon={<RiLogoutBoxLine />}
        onClick={() => dispatch(startLogout()).then(() => {
          setCollapsedMenu(!collapsedMenu);
          setLogin(null);
        })}
      >
        <span className="dropdown-header">
          {t('logout')}
        </span>
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout className="vh-100 w-100">
      <Sidebar
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        displayDrawer={displayDrawer}
        login={login}
      />
      <Layout className="layout-container">
        {login && (
          <Header>
            <Row className="w-100 header">
              {displayDrawer && (
              <Col className="navbar__icon-container" onClick={() => setOpenDrawer(true)}>
                <RiMenuLine className="navbar__arrow-icon" />
              </Col>
              ) }
              <Col className="header__language-menu">
                <LanguagesMenu translator={t} i18n={i18n} />
              </Col>
              <Col className="header__user-information">
                <Row className="user-info-container" align="middle">
                  <Dropdown
                    onOpenChange={(e) => setCollapsedMenu(e)}
                    overlay={menu}
                    placement="bottomLeft"
                    open={collapsedMenu}
                    arrow
                    trigger={['click']}
                    autoFocus
                  >
                    {headerMenu}
                  </Dropdown>
                </Row>
              </Col>
            </Row>
          </Header>
        )}
        { showBanner && <Banner message={t('bannerText')} />}
        <Content className="children-container">
          {login && (
            <CustomBreadCrumb items={breadcrumb} />
          )}
          <div className={`${login ? 'main-content' : 'public-content'}`}>
            {children}
          </div>
        </Content>
        {login && (
        <Footer>
          <CustomFooter />
        </Footer>
        )}
      </Layout>
    </Layout>
  );
};

export default Navbar;
