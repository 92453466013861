import React from 'react';
import { Breadcrumb } from 'antd';
import './styles.scss';

interface CustomBreadCrumbProps {
  items: string[],
}
const CustomBreadCrumb:React.FC<CustomBreadCrumbProps> = ({ items }) => (
  <Breadcrumb style={{ paddingLeft: '3%' }} className="custom-breadcrumb">
    {items?.map((item, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Breadcrumb.Item key={index}>
        {item}
      </Breadcrumb.Item>
    ))}
  </Breadcrumb>
);

export default CustomBreadCrumb;
