import { TFunction } from 'i18next';
import asyncComponent from '../components/AsyncComponent';
import routesDictionary from './routesDictionary';

const AsyncLoginComponent = asyncComponent(() => import('../views/Auth/Login'));
const AsyncProgramList = asyncComponent(() => import('../views/Programs/ProgramList'));
const AsyncCoursesForProgram = asyncComponent(() => import('../views/Programs/ProgramList/ViewCoursesPrograms'));
const AsyncAllOperatingPlan = asyncComponent(() => import('../views/OperatingPlan/AllOperatingPlanView'));
const AsyncAllOperatingPlanProgram = asyncComponent(() => import('../views/OperatingPlan/AllOperatingPlanProgramView'));
const AsyncOperatingPlan = asyncComponent(() => import('../views/OperatingPlan/OperatingPlanProgramView'));
const AsyncCreateProfessor = asyncComponent(() => import('../views/OperatingPlan/CreateProfessorView'));
const AsyncOperatingPlanProgramProfessor = asyncComponent(() => import('../views/OperatingPlan/ProfessorView'));
const AsyncOperatingPlanProgramSubject = asyncComponent(() => import('../views/OperatingPlan/SubjectView'));
const AsyncSubjectList = asyncComponent(() => import('../views/Subjects/SubjectList'));
const AsyncSubjectDetails = asyncComponent(() => import('../views/Subjects/SubjectDetails'));
const AsyncProfessorList = asyncComponent(() => import('../views/Professors/ProfessorList'));
const AsyncProfessorDetails = asyncComponent(() => import('../views/Professors/ProfessorDetails'));
const AsyncProfessorCreate = asyncComponent(() => import('../views/Professors/CreateProfessor'));
const AsyncHome = asyncComponent(() => import('../views/Home'));
const AsyncProfessorCourseList = asyncComponent(() => import('../views/Professsor/ProfessorSubjects/SubjectList'));
const AsyncProfessorCourse = asyncComponent(() => import('../views/Professsor/ProfessorSubjects/SubjectView'));
const AsyncAttendance = asyncComponent(() => import('../views/Professsor/ProfessorSubjects/Attendance'));
const AsyncEnrollmentsProgramList = asyncComponent(() => import('../views/Enrollments/Enrollments/ProgramList'));
const AsyncEnrollmentsProgram = asyncComponent(() => import('../views/Enrollments/Enrollments/ProgramEnrollments'));
const AsyncEnrollmentsProgramSubject = asyncComponent(() => import('../views/Enrollments/Enrollments/ProgramSubjectEnrollments'));
const AsyncEntrantsProgramList = asyncComponent(() => import('../views/Entrants/EntrantsProgramList'));
const AsyncEntrantsProgram = asyncComponent(() => import('../views/Entrants/EntrantsProgram'));
const StudyPlansProgramList = asyncComponent(() => import('../views/StudyPlans/StudyPlansProgramList'));
const StudyPlansProgram = asyncComponent(() => import('../views/StudyPlans/StudyPlansProgram'));
const AsyncStudentSubjectList = asyncComponent(() => import('../views/Student/CourseList'));
const AsyncStudentSubject = asyncComponent(() => import('../views/Student/CourseView'));
const AsyncManualEnrollment = asyncComponent(() => import('../views/Enrollments/Enrollments/ManualEnrollment'));
const AsyncProfessorAcademicGrades = asyncComponent(() => import('../views/Professsor/AcademicGrades/SubjectList'));
const AsyncProfessorStudentList = asyncComponent(() => import('../views/Professsor/AcademicGrades/StudentList'));
const AsyncAcademicManagementCourseList = asyncComponent(() => import('../views/AcademicManagement/CourseList'));
const AsyncAcademicManagementCourse = asyncComponent(() => import('../views/AcademicManagement/CourseView'));
const AsyncAcademicManagementAttendance = asyncComponent(() => import('../views/AcademicManagement/Attendance'));
const AsyncEnrollmentUpdate = asyncComponent(() => import('../views/Enrollments/EnrollmentUpdate/List'));
const AsyncEnrollmentUpdateStudent = asyncComponent(() => import('../views/Enrollments/EnrollmentUpdate/Student'));
const AsyncUserList = asyncComponent(() => import('../views/Users/UserList'));
const AsyncPaymentSchedulesList = asyncComponent(() => import('../views/Payments/PaymentSchedules'));
const AsyncPaymentManagementProgramList = asyncComponent(() => import('../views/Payments/PaymentManagement/PaymentsProgramList'));
const AsyncPaymentManagementProgram = asyncComponent(() => import('../views/Payments/PaymentManagement/PaymentsProgram'));

const routes = (t: TFunction) => [
  {
    path: routesDictionary.coursesForProgram.route,
    component: AsyncCoursesForProgram,
    type: 'private',
    title: t('programsForUnit'),
    module: routesDictionary.coursesForProgram,
  },
  {
    path: routesDictionary.programsForUnit.route,
    component: AsyncProgramList,
    type: 'private',
    title: t('programsForUnit'),
    module: routesDictionary.programsForUnit,
  },
  {
    path: routesDictionary.paymentSchedules.route,
    component: AsyncPaymentSchedulesList,
    type: 'private',
    title: t('paymentSchedules'),
    module: routesDictionary.paymentSchedules,
  },
  {
    path: routesDictionary.paymentManagement.route,
    component: AsyncPaymentManagementProgramList,
    type: 'private',
    title: t('payments'),
    module: routesDictionary.paymentManagement,
  },
  {
    path: routesDictionary.programPaymentManagement.route,
    component: AsyncPaymentManagementProgram,
    type: 'private',
    title: t('payments'),
    module: routesDictionary.programPaymentManagement,
  },
  {
    path: routesDictionary.users.route,
    component: AsyncUserList,
    type: 'private',
    title: t('users'),
    module: routesDictionary.users,
  },
  {
    path: routesDictionary.createEnrollmentRemoval.route,
    component: AsyncEnrollmentUpdateStudent,
    type: 'private',
    title: t('enrollmentRemoval'),
    module: routesDictionary.createEnrollmentRemoval,
  },
  {
    path: routesDictionary.enrollmentRemovalStudent.route,
    component: AsyncEnrollmentUpdateStudent,
    type: 'private',
    title: t('enrollmentRemoval'),
    module: routesDictionary.enrollmentRemovalStudent,
  },
  {
    path: routesDictionary.enrollmentRemoval.route,
    component: AsyncEnrollmentUpdate,
    type: 'private',
    title: t('enrollmentRemoval'),
    module: routesDictionary.enrollmentRemoval,
  },
  {
    path: routesDictionary.createEnrollmentUpdate.route,
    component: AsyncEnrollmentUpdateStudent,
    type: 'private',
    title: t('enrollmentUpdate'),
    module: routesDictionary.createEnrollmentUpdate,
  },
  {
    path: routesDictionary.enrollmentUpdateStudent.route,
    component: AsyncEnrollmentUpdateStudent,
    type: 'private',
    title: t('enrollmentUpdate'),
    module: routesDictionary.enrollmentUpdateStudent,
  },
  {
    path: routesDictionary.enrollmentUpdate.route,
    component: AsyncEnrollmentUpdate,
    type: 'private',
    title: t('enrollmentUpdate'),
    module: routesDictionary.enrollmentUpdate,
  },
  {
    path: routesDictionary.programSubjectAttendance.route,
    component: AsyncAcademicManagementAttendance,
    type: 'private',
    title: t('academicManagement'),
    module: routesDictionary.programSubjectAttendance,
  },
  {
    path: routesDictionary.programSubjectAcademicManagement.route,
    component: AsyncAcademicManagementCourse,
    type: 'private',
    title: t('academicManagement'),
    module: routesDictionary.programSubjectAcademicManagement,
  },
  {
    path: routesDictionary.academicManagement.route,
    component: AsyncAcademicManagementCourseList,
    type: 'private',
    title: t('academicManagement'),
    module: routesDictionary.academicManagement,
  },
  {
    path: routesDictionary.studentList.route,
    component: AsyncProfessorStudentList,
    type: 'private',
    title: t('academicGrades'),
    module: routesDictionary.studentList,
  },
  {
    path: routesDictionary.professorAcademicGrades.route,
    component: AsyncProfessorAcademicGrades,
    type: 'private',
    title: t('Academic Grades'),
    module: routesDictionary.professorAcademicGrades,
  },
  {
    path: routesDictionary.manualEnrollment.route,
    component: AsyncManualEnrollment,
    type: 'private',
    title: t('manualEnrollments'),
    module: routesDictionary.manualEnrollment,
  },
  {
    path: routesDictionary.studentSubject.route,
    component: AsyncStudentSubject,
    type: 'private',
    title: t('mySubjects'),
    module: routesDictionary.studentSubject,
  },
  {
    path: routesDictionary.studentSubjects.route,
    component: AsyncStudentSubjectList,
    type: 'private',
    title: t('mySubjects'),
    module: routesDictionary.studentSubjects,
  },
  {
    path: routesDictionary.attendance.route,
    component: AsyncAttendance,
    type: 'private',
    title: t('attendance'),
    module: routesDictionary.attendance,
  },
  {
    path: routesDictionary.programStudyPlans.route,
    component: StudyPlansProgram,
    type: 'private',
    title: t('studyPlans'),
    module: routesDictionary.programStudyPlans,
  },
  {
    path: routesDictionary.studyPlans.route,
    component: StudyPlansProgramList,
    type: 'private',
    title: t('studyPlans'),
    module: routesDictionary.studyPlans,
  },
  {
    path: routesDictionary.programEntrants.route,
    component: AsyncEntrantsProgram,
    type: 'private',
    title: t('entrants'),
    module: routesDictionary.programEntrants,
  },
  {
    path: routesDictionary.entrants.route,
    component: AsyncEntrantsProgramList,
    type: 'private',
    title: t('entrants'),
    module: routesDictionary.entrants,
  },
  {
    path: routesDictionary.programSubjectEnrollments.route,
    component: AsyncEnrollmentsProgramSubject,
    type: 'private',
    title: t('enrollments'),
    module: routesDictionary.programSubjectEnrollments,
    hide: true,
  },
  {
    path: routesDictionary.programEnrollments.route,
    component: AsyncEnrollmentsProgram,
    type: 'private',
    title: t('enrollments'),
    module: routesDictionary.programEnrollments,
  },
  {
    path: routesDictionary.enrollments.route,
    component: AsyncEnrollmentsProgramList,
    type: 'private',
    title: t('enrollments'),
    module: routesDictionary.enrollments,
  },
  {
    path: routesDictionary.professorCourse.route,
    component: AsyncProfessorCourse,
    type: 'private',
    title: t('mySubject'),
    module: routesDictionary.professorCourse,
  },
  {
    path: routesDictionary.professorAllCourses.route,
    component: AsyncProfessorCourseList,
    type: 'private',
    title: t('mySubjects'),
    module: routesDictionary.professorAllCourses,
  },
  {
    path: routesDictionary.professorDetails.route,
    component: AsyncProfessorDetails,
    type: 'private',
    title: t('professor'),
    module: routesDictionary.professorDetails,
  },
  {
    path: routesDictionary.professors.route,
    component: AsyncProfessorList,
    type: 'private',
    title: t('professors'),
    module: routesDictionary.professors,
  },
  {
    path: routesDictionary.subjectDetails.route,
    component: AsyncSubjectDetails,
    type: 'private',
    title: t('subject'),
    module: routesDictionary.subjectDetails,
  },
  {
    path: routesDictionary.createProfessor.route,
    component: AsyncProfessorCreate,
    type: 'private',
    title: t('createProfessor'),
    module: routesDictionary.createProfessor,
  },
  {
    path: routesDictionary.subjects.route,
    component: AsyncSubjectList,
    type: 'private',
    title: t('subjects'),
    module: routesDictionary.subjects,
  },
  {
    path: routesDictionary.operatingPlanProgramSubject.route,
    component: AsyncOperatingPlanProgramSubject,
    type: 'private',
    title: t('subject'),
    module: routesDictionary.operatingPlanProgramSubject,
  },
  {
    path: routesDictionary.operatingPlanProgramProfessor.route,
    component: AsyncOperatingPlanProgramProfessor,
    type: 'private',
    title: t('professor'),
    module: routesDictionary.operatingPlanProgramProfessor,
  },
  {
    path: routesDictionary.operatingPlanProgramCreateProfessor.route,
    component: AsyncCreateProfessor,
    type: 'private',
    title: t('createProfessor'),
    module: routesDictionary.operatingPlanProgramCreateProfessor,
  },
  {
    path: routesDictionary.operatingPlanProgram.route,
    component: AsyncOperatingPlan,
    type: 'private',
    title: t('operatingPlan'),
    module: routesDictionary.operatingPlanProgram,
  },
  {
    path: routesDictionary.allOperatingPlanProgram.route,
    component: AsyncAllOperatingPlanProgram,
    type: 'private',
    title: t('operatingPlan'),
    module: routesDictionary.allOperatingPlanProgram,
  },
  {
    path: routesDictionary.allOperatingPlan.route,
    component: AsyncAllOperatingPlan,
    type: 'private',
    title: t('operatingPlan'),
    key: 'allOperatingPlan',
    module: routesDictionary.allOperatingPlan,
  },
  {
    path: routesDictionary.home.route,
    component: AsyncHome,
    type: 'private',
    title: t('home'),
    module: routesDictionary.home,
  },
  {
    path: routesDictionary.login,
    component: AsyncLoginComponent,
    type: 'public',
    title: t('login'),
    module: routesDictionary.login,
  },
];

export default routes;
