import React from 'react';
import moment from 'moment';
import i18next from 'i18next';
import {
  BACKEND_URL, IN_PROGRESS, OBSERVED, UNIT_DIRECTOR,
} from './constants';

export const getMediaLink = (mediaUrl: string) => `${BACKEND_URL}/media/${mediaUrl}`;

export const generateUrlDownload = (url: string) => `${BACKEND_URL}${url}`;

export const displayComponent = (
  userType: string,
  status: string,
  component: React.ReactNode,
  isActive: boolean,
) => {
  if (userType === UNIT_DIRECTOR && (status === IN_PROGRESS || status === OBSERVED) && isActive) {
    return component;
  }

  return <div />;
};
export const isNull = (value: any) => value === null;

export const isUndefined = (value: any) => value === undefined;
export const isNumber = (value: any) => typeof value === 'number';
export default {};

export const isToday = (date: any) => moment(date).isSame(moment(), 'day');

export const getCurrentLanguage = () => i18next.language.toLowerCase();
