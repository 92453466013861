import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { authSlice } from './slices/auth';
import { uiSlice } from './slices/ui';

const store = configureStore({
  reducer: combineReducers({
    auth: authSlice.reducer,
    ui: uiSlice.reducer,
  }),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export default store;

export type AppDispatch = typeof store.dispatch
