import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import routesDictionary from './routesDictionary';
import { permissions } from './constants';
import { UNIT_DIRECTOR } from '../utils/constants';

interface RouteElementProps {
    path: string
    component: any
    type: string
    title: string
    login: string | null | undefined
    setLogin: Dispatch<SetStateAction<string | null | undefined>>
    module: any
}

type typeRouteOptions = {
  // eslint-disable-next-line no-unused-vars
    [key: string]: (props: RouteElementProps) => React.ReactElement
}

const routeTypes : typeRouteOptions = {
  private: (props : RouteElementProps) => {
    const {
      component: Component, login, module,
    } = props;

    const { userType, graduateUnitId } = useSelector((state: any) => state.auth);
    const { pathname } = useLocation();
    if (!login) {
      return (
        <Navigate replace to={routesDictionary.login} />
      );
    }

    if (userType === UNIT_DIRECTOR) {
      const params = useParams();
      const { operatingPlanId } = params;
      if (pathname === routesDictionary.allOperatingPlan.route
        || (operatingPlanId && (graduateUnitId !== operatingPlanId))) {
        return (
          <Navigate replace to={routesDictionary.allOperatingPlanProgram.func(graduateUnitId)} />
        );
      }
    }

    if (!permissions[userType]?.some((permission) => permission === module.moduleName)) {
      return (
        <Navigate replace to={routesDictionary.home.route} />
      );
    }

    return (
      <Component />
    );
  },

  public: (props : RouteElementProps) => {
    const {
      component: Component, login, setLogin,
    } = props;

    if (login) {
      return (
        <Navigate replace to={routesDictionary.home.route} />
      );
    }
    return (
      <Component setLogin={setLogin} />
    );
  },

  persistent: (props : RouteElementProps) => {
    const {
      component: Component,
    } = props;

    return (
      <Component />
    );
  },
};

const RouteElement : React.FC<RouteElementProps> = (props) => {
  const {
    path, title,
    component: Component, type, login, setLogin, module,
  } = props;

  useEffect(() => {
    document.title = title;
  }, []);

  return (
    routeTypes[type]({
      component: Component,
      path,
      type,
      title,
      login,
      setLogin,
      module,
    })
  );
};

export default RouteElement;
