const resourceES = {
  applicationName: 'SISTEMA DE GESTION DE PROCESOS',
  welcome: 'Bienvenido',
  email: 'Correo electronico',
  requiredEmail: '¡Ingrese su correo electronico!',
  noValidEmail: '¡No es un correo electrónico válido!',
  password: 'Contraseña',
  requiredPassword: '¡Por favor ingrese su contraseña!',
  login: 'Iniciar sesion',
  loginGoogle: 'Ingresar con cuenta institucional',

  english: 'Ingles',
  spanish: 'Español',
  logout: 'Cerrar Sesion',

  operatingPlans: 'Planes de funcionamiento',
  programs: 'Programas',
  of: 'de',
  items: 'registros',

  // Programs
  programsForUnit: 'Programas y Cursos',
  programsAndUnit: 'Programas & Unidades',
  registerPrograms: 'Registrar Programas',
  registerProgram: 'Registrar Programa',
  registerProgramsTitle: 'Registrar Programas',
  registerProgramsInfoText: 'Cargue el archivo en formato Excel .xslx con la relacion de programas para ser registrado en el sistema',

  // Home
  studyPrograms: 'Programas de Estudio',
  operatingPlanStatus: 'Estado del plan de funcionamiento',
  graduateSchool: 'Escuela de Posgrado',
  graduateUnits: 'Unidades de Posgrado',
  units: 'Unidades',
  operatingPlanProgress: 'Porcentaje de planes de funcionamiento aprobados',
  studyPlanUpdateRequests: 'Solicitudes de actualización del plan de estudios',
  emptyStudyPlanUpdateRequests: 'No tiene solicitudes pendientes',
  requestDate: 'Fecha de Envio',

  // All operating plan view
  code: 'Codigo',
  unitManager: 'Director de Unidad',
  graduateUnit: 'Unidad de Posgrado',
  receptionDate: 'Fecha de Recepción',
  approvalDate: 'Fecha de Aprobación',
  state: 'Estado',
  actions: 'Acciones',
  review: 'Revisar',

  // Operating Plan Process
  operatingPlanProcessInfoText: 'Permitir la apertura del plan de funcionamiento del presente año para que las unidades de posgrado puedan registrar sus planes de funcionamiento en el sistema.',
  processLetter: 'Oficio de apertura',
  descriptiveIndex: 'Documento de Estructura de Plan de Funcionamiento',
  opIndex: 'Indice de Plan de Funcionamiento (.xlsx)',
  openOperatingPlan: 'Aperturar Plan de Funcionamiento',
  downloadOperatingPlanIndexTemplate: 'Descargar plantilla de indice de plan de funcionamiento',

  // All operating plan program view
  number: 'Numero',
  type: 'Tipo',
  program: 'Programa',
  viewObservations: 'Ver observaciones',
  download: 'Descargar',
  view: 'Ver',
  observe: 'Observar',
  inProgress: 'En curso',
  isApproved: 'Aprobado',
  sent: 'Enviado',
  isObserved: 'Observado',
  year: 'Año',
  academicYear: 'Año Academico',
  approvalRD: 'R.D. de aprobación',

  // Filter component
  programType: 'Tipo de programa',
  status: 'Estado',
  firstName: 'Nombres',
  lastName: 'Apellidos',
  documentType: 'Tipo de documento',
  documentNumber: 'Nro de documento',
  subject: 'Asignatura',

  // Sidebar
  home: 'Inicio',
  operatingPlan: 'Plan de funcionamiento',
  professors: 'Docentes',
  students: 'Estudiantes',
  courses: 'Cursos',
  users: 'Usuarios',

  // Operating plan view
  send: 'Enviar',
  operatingPlanTemplate: 'Plantilla de P.F.',
  downloadOperatingPlanDocument: 'Descargar plan de funcionamiento',
  downloadOperatingPlanTemplate: 'Descargar plantilla de P.F.',
  downloadFile: 'Descargar documento',

  // Operating plan professors view
  newProfessor: 'Nuevo docente',
  country: 'País',
  gender: 'Género',
  FEMALE: 'Femenino',
  MALE: 'Masculino',
  orcidId: 'ORCID-ID',
  studyPlan: 'Plan de estudios',
  positions: 'Otros cargos',
  director: 'Director',
  coodinator: 'Coordinador',
  directorAndCoordinator: 'Director y Coordinador',
  noCharges: 'No posee cargos',
  documentIdentification: 'Documento de Identidad',
  uploadProfessorFileInfoText: 'Registre los docentes del plan de funcionamiento cargando el documento con la relación de docentes',
  downloadProfessorTemplate: 'Descargar plantilla para el registro de docentes',
  registerProfessorList: 'Registrar lista de docentes',
  uploadProfessorList: 'Subir relacion de docentes',
  downloadProfessorList: 'Descargar lista de docentes',

  // Operating plan subjects view
  subjects: 'Asignaturas',
  name: 'Nombre',
  credits: 'Créditos',
  totalHours: 'Total horas',
  semester: 'Semestre',

  // Create professor view
  search: 'Búsqueda',
  verifyDocumentNumber: 'Verificar número de documento',
  verify: 'Verificar',
  male: 'Masculino',
  female: 'Femenino',
  birthDate: 'Fecha de nacimiento',
  personalInformation: 'Datos personales',
  contactInformation: 'Datos de contacto',
  phone: 'Celular',
  professorType: 'Tipo de docente',
  academicData: 'Datos académicos',
  universityTitle: 'Título profesional',
  topDegree: 'Mayor grado académico',
  curriculumVitae: 'Currículum Vitae',
  degrees: 'Grados académicos y títlulos',
  investigations: 'Investigaciones',
  sunedu: 'SUNEDU',
  saveChanges: 'Guardar cambios',
  guest: 'Invitado',
  fromUniversity: 'De la universidad',
  viewDegrees: 'Ver grados',
  degreesEmpty: 'No hay grados academicos disponibles.',
  degree: 'Grado académico',
  institution: 'Institución',
  constancySunedu: 'Constancia SUNEDU',
  mention: 'Mención',
  degreeDiploma: 'Diploma de grado',
  professorHasBeenFound: '!Se ha encontrado un docente!',
  professorHasBeenNotFound: '!No se ha encontrado un profesor, por favor completa los datos!',
  professorIsNotRegistered: '¡Por favor, completa los datos para agregar al docente!',
  professorAlredyExists: '¡El docente {{firstName}} {{lastName}} {{maternalLastName}} ya se encuentra registrado en el programa de estudios!',

  // Edit subject view
  hours: 'Horas',
  syllabus: 'Silabo',
  registerSyllabus: 'Registrar silabo',
  groups: 'Grupos',
  professor: 'Docente',
  classroom: 'Aula',
  startEndDate: 'Fecha inicio - fin',
  capacity: 'Capacidad',
  group: 'Grupo',
  schedules: 'Horarios',
  enabled: 'Activo',
  assignedCourses: 'Cursos Asignados',
  registerSchedules: 'Registrar horarios',
  viewSchedules: 'Ver Horarios',
  addGroup: 'Agregar grupo',
  schedulesEmpty: 'No hay horarios disponibles.',
  groupsEmpty: 'No hay grupos disponibles.',
  viewSyllabus: 'Ver silabo',
  missingSyllabus: 'No registrado',
  topDegreeLabel: 'No posee algún grado',
  universityTitleLabel: 'No tiene titulo universitario',

  // Schedule Modal
  add: 'Agregar',
  cancel: 'Cancelar',
  day: 'Dia',
  startTime: 'Hora de inicio',
  endTime: 'Hora de fin',
  saveSchedules: 'Guardar horarios',
  addSchedule: 'Agregar horario',
  // User groups:
  Professor: 'Docente',
  Student: 'Estudiante',
  'Unit manager': 'Director de unidad',
  'School manager': 'Director de escuela',

  // Breadcrumb items
  'operating-plan': 'Plan de funcionamiento',
  observations: 'Observaciones',
  edit: 'Editar',
  delete: 'Eliminar',
  createProfessor: 'Crear docente',
  sendObservations: 'Enviar observaciones',
  approve: 'Aprobar',
  observationsModalText: 'Marque las secciones aprobadas del plan de funcionamiento, o ingrese las observaciones correspondientes en cada sección',
  observationModalTittle: 'Observaciones de plan de funcionamiento',
  observationsPlaceholder: 'Ingrese aquí sus observaciones',
  observationsSentModalText: 'Las secciones no marcadas no están aprobadas o tienen observaciones.',

  // All operating plan view
  unitDirector: 'Director de unidad',
  academicProgress: 'Progreso academico',
  mySubjects: 'Mis cursos',
  percentage: 'Porcentaje',
  startDate: 'Fecha de inicio',
  endDate: 'Fecha de fin',
  academicGrades: 'Notas Academicas',
  grades: 'Notas',
  registerGrades: 'Registrar notas',
  downloadGrades: 'Descargar Notas',
  attendanceTooltip: 'El estado de asistencia del alumno se basa en 4 criterios: P (Presente), T (Tarde), A (Ausente) y J (Justificado)',
  assignGrades: 'Asignar Notas',
  editGrades: 'Editar Notas',
  summery: 'Resumen',
  approved: 'Aprobados',
  notApproved: 'No Aprobados',
  dateOpSent: 'Fecha de Envio del Plan de F.',
  academicManagement: 'Gestion Academica',

  // Enrollments
  enrollments: 'Matriculas',
  enrollment: 'Matricula',
  registerStudents: 'Registrar estudiantes',
  confirm: 'Confirmar',
  registerProgramStudents: 'Registrar estudiantes por programa',
  registerSubjectStudents: 'Registrar estudiantes por asignatura',
  registerProgramStudentsText: 'Al cargar una lista de matriculados nueva se eliminarán todos los registros existentes a excepción de aquellos registrados por reactualización de matrícula',
  registerSubjectStudentsText: 'Para el registro de matriculados en la asignatura solo se considerará los registros de los alumnos previamente matriculados en el periodo de matrícula correspondiente',
  confirmationMessage: 'Mensaje de Confirmacion',
  uploadFile: 'Subir archivo',
  numberGroups: 'Cantidad de Grupos',
  viewStudents: 'Ver alumnos',
  assignGroups: 'Asignar Grupos',
  editGroups: 'Editar Grupos',
  save: 'Guardar',
  enrollmentPeriod: 'Periodo de matricula',
  consolidated: 'Consolidado',
  errorAddGroupEnrollment: 'Archivo invalido o aun no tiene grupos registrados en el curso',
  period: 'Periodo',
  condition: 'Condicion',
  enrolled: 'Matriculados',
  subjectsNumber: 'Nro de cursos',
  process: 'Proceso',
  downloadStudentsList: 'Descargar lista de estudiantes',
  studentsNum: 'Nro Estudiantes',
  deleteStudentListText: 'Todos los registros de matriculados serán eliminados permanentemente a excepción de los estudiantes matriculados por Reactualización de matricula, esto incluye la eliminación de los registros de matriculados en cada asignatura dentro de este periodo. ¿Desea continuar?',
  voidPeriod: 'Periodo o año no seleccionado',
  grade: 'Nota',
  emptyCourseGroupsTitle: 'Todavia no hay grupos creados para este curso',
  emptyCourseGroupsDescription: 'Le recomendamos que realice el registro de los grupos para el curso',
  registerGroups: 'Registrar grupos',

  // Entrants
  entrants: 'Ingresantes',
  birthday: 'Fecha de Nacimiento',
  registerEntrants: 'Registrar ingresantes',
  admissionProcesses: 'Procesos de Ingreso',
  admissionProcess: 'Proceso de Ingreso',
  admissionYear: 'Año de Ingreso',
  registerAdmissionProcess: 'Registrar Proceso de Admision',
  registerAdmissionProcessInfoText: 'Ingrese el año y el nombre del proceso de ingreso a ser registrado en el sistema, asimismo, cargue el archivo en formato Excel .xlsx con la relación de ingresantes para ser registrado en el sistema',
  invalidFile: 'Archivo invalido',
  emptyEntrantsTitle: 'Todavia no hay un proceso de admision registrado para el programa de estudios',
  emptyEntrantsDescriptionUnitDirector: 'Le recomendamos que espere a que el director de la escuela registre un proceso de admision',
  emptyEntrantsDescriptionSchoolDirector: 'Le recomendamos que registre un proceso de admision',

  // Study Plans
  'study-plans': 'Planes de Estudio',
  studyPlans: 'Planes de Estudio',
  requestUpdate: 'Solicitar actualizacion',
  updateStudyPlan: 'Actualizacion plan de estudios',
  summaryChanges: 'Resumen de cambios',
  approvalResolution: 'Resolucion de aprobacion',
  viewRequest: 'Ver solicitud',
  accept: 'Aceptar',
  rejectedResolution: 'Resolucion de rechazo',
  update: 'Actualizar',

  // Empty Descriptions
  emptyOperatingPlanTitle: 'Todavia no hay un plan de funcionamiento aperturado',
  emptyOperatingPlanDescriptionUnitDirector: 'Le recomendamos que espere a que el director de la escuela aperture un plan de funcionamiento',
  emptyOperatingPlanDescriptionSchoolDirector: 'Le recomendamos que empiece por aperturar un plan de funcionamiento',
  emptyOperatingPlanProcessDescriptionSchoolDirector: 'Le recomendamos que empiece por aperturar un plan de funcionamiento en la sección \'Plan de Funcionamiento\'',
  studyPlanTemplate: 'Plantilla de plan de estudios',
  downloadStudyPlanTemplate: 'Descargar Plantilla para subir plan de estudios',
  operatingPlanNotApprovedTitle: 'El plan de funcionamiento aún está en proceso o no ha sido aprobado',
  operatingPlanNotApprovedDescriptionUnitDirector: 'Le recomendamos que envíe el plan de funcionamiento a la dirección de la escuela de posgrado y espere a que sea aprobado.',
  operatingPlanNotApprovedDescriptionSchoolDirector: 'Le recomendamos que revise si la unidad ha enviado el plan de funcionamiento para su aprobación.',
  emptyOperatingPlanProgramsTitle: 'Aún no tiene ningún curso registrado en un programa de estudios',
  emptyOperatingPlanProgramsDescriptionStudent: '',
  emptyOperatingPlanProgramsDescriptionProfessor: 'Le recomendamos esperar a que se registren los cursos en el plan de estudios.',
  emptyEnrollmentPeriodsTitle: 'Matriculas no habilitadas',
  emptyEnrollmentPeriodsDescription: 'Le recomendamos que envíe el plan de funcionamiento a la dirección de la escuela de posgrado y espere a que sea aprobado para empezar con el proceso de registro de matriculas.',

  statusTooltip: 'Se marcará como completado si la asignatura tiene registrado su sílabo y existe al menos un grupo agregado',
  homeView: 'Inicio',
  attendance: 'Asistencia',
  registerAcademicProgress: 'Registrar progreso academico',
  viewAcademicProgress: 'Ver progreso academico',
  description: 'Descripción',
  topic: 'Tema',
  date: 'Fecha',
  myCourses: 'Mis cursos',
  academicSessions: 'Sesiones académicas',
  registerAttendance: 'Registrar asistencia',
  registerAttendanceText: 'Resistre su asistencia aqui',
  viewAttendance: 'Ver asistencia',
  allowAttendance: 'Habilitar asistencia',
  allowStudentAttendance: 'Habilitar registro de asistencia',
  allowStudentAttendanceText: '¿Desea permitir que los esudiantes registren su propia asistencia?',
  registerAttendanceTooltip: 'Registre las notas académicas una vez finalizado el curso. Las fechas de inicio y fin en la tabla son las del proceso de registro',

  present: 'Presente',
  absent: 'Ausente',
  late: 'Tarde',
  excusedAbsence: 'Ausencia justificada',

  emptyAcademicSessions: 'Todavia no ha creado las sesiones academicas.',
  emptyAcademicSessionsDescription: 'Le recomendamos que cree las sesiones academicas presionando el boton.',
  createAcademicSessions: 'Crear sesiones academicas',
  all: 'Todos',
  document: 'Documento',
  sortAscending: 'Haga clic para ordenar de forma ascendente',
  sortDescending: 'Haga clic para ordenar de forma descendente',
  cancelSorting: 'haga clic para cancelar el ordenamiento',
  afterUploadProfessorList: 'No olvide registrar la información académica de los docentes registrados',
  upload: 'Subir',
  postponementDate: 'Fecha de aplazamiento',
  postpone: 'Aplazar',
  emptyCharacter: 'ㅤ',
  viewCourses: 'Ver cursos',
  programCourses: 'Cursos del programa',
  viewCourse: 'Ver curso',
  externalCourses: 'Cursos externos',
  coursesEmpty: 'No hay cursos disponibles',
  bannerText: 'El plazo para el registro del plan de funcionamiento ha vencido',
  export: 'Exportar',
  professorProfile: 'Perfil docente',
  maternalLastName: 'Apellido materno',
  fatherLastName: 'Apellido paterno',
  dni: 'DNI',
  foreignCard: 'Carnet de extranjería',

  // Student
  myEnrollments: 'Mis matriculas',
  withdrawalOfEnrollment: 'Retiro de matricula',
  withdrawalOfEnrollmentInfoText: 'Seleccione los cursos en los cuales desea retirar su matrícula y cargue el documento con la solicitud de retiro correspondiente. En dicho documento adjuntar el comprobante de pago por concepto de retiro de matrícula',
  withdrawalRequest: 'Solicitud de Retiro de Matricula',
  voucher: 'Comprobante de pago',
  withdrawType: 'Tipo de retiro',
  Partial: 'Parcial',
  Total: 'Total',
  student: 'Estudiante',
  removalResolution: 'Resolucion Directoral',
  requestReport: 'Solicitar Informe',
  sendReport: 'Enviar Informe',
  removalReport: 'Informe de retiro de matricula',
  withdawalIsApprove: 'Aprobar o Rechazar retiro de matricula',
  reject: 'Rechazar',
  sendRequest: 'Enviar Solicitud',
  emptyWithdrawalTitle: 'Todavia no hay solicitudes de retiro de matricula',
  emptyWithdrawalDescriptionUnitDirector: '',
  emptyWithdrawalDescriptionSchoolDirector: '',
  manualEnrollment: 'Matricula manual',
  cui: 'CUI',
  studentHasBeenFound: '!Se ha encontrado un estudiante!',
  studentHasBeenNotFound: '!No se ha encontrado un estudiante, porfavor completa los datos!',
  enrollmentDocuments: 'Datos de matricula',
  studyProgram: 'Programa de estudio',
  resolution: 'Resolucion',
  resolutionNumber: 'N° de resolución',
  numberOfGroups: 'N° de grupos',
  disable: 'Deshabilitar',
  equivalentCourse: 'Curso Equivalente',

  // Confirmation modal
  sendOperatingPlan: 'Enviar plan de funcionamiento',
  observeOperatingPlan: 'Observar plan de funcionamiento',
  approveOperatingPlan: 'Aprobar plan de funcionamiento',
  deleteRegistrationProfessor: 'Eliminar registro de profesor',
  deleteAcademicProgress: 'Eliminar progreso academico',
  deleteStudentProgramList: 'Eliminar lista de alumnos matriculados',
  disableProfessorRegistration: 'Deshabilitar registro de profesor',
  approveOperatingPlanText: '¿Esta seguro de aprobar el plan de funcionamiento?',
  sendOperatingPlanText: '¿Esta seguro que desea enviar el plan de funcionamiento al director de escuela para su aprobación?',
  observeOperatingPlanText: '¿Esta seguro que desea enviar las observaciones del plan de funcionamiento?',
  deleteRegistrationProfessorText: '¿Esta seguro que desea eliminar el registro del docente?',
  deleteAcademicProgressText: '¿Esta seguro que desea eliminar el avance academico?',
  disableProfessorRegistrationText: 'Si un docente es deshabilitado, no podrá ser asignado a ninguna asignatura. ¿Desea continuar?',

  enrollmentUpdate: 'Reactualización de matricula',
  addUpdate: 'Añadir reactualización',
  studentData: 'Datos del estudiante',
  entrantCode: 'Codigo de ingreso',
  numberCourses: 'Nro de cursos',
  numberCredits: 'Total de creditos',
  subjectEnrollment: 'Asignaturas',
  numberEnrollment: 'N° de matricula',
  addCourse: 'Añadir asignatura',
  lastEnrollmentYear: 'Año ultima matricula',
  enrollmentRemoval: 'Retiro de matricula',
  addRemoval: 'Añadir retiro de matricula',
  studentHasBeenNotFoundCui: '!No se ha encontrado un estudiante, porfavor verifique que el cui ingresado sea el correcto!',
  removalType: 'Tipo de retiro',
  updates: 'Reactualizaciones',
  removes: 'Retiros',
  enrollmentsDelete: 'Se eliminarán los registros de matrícula en el curso ¿Desea continuar?',
  deleteCourseEnrollments: 'Eliminar matriculas',
  studentHasBeenFoundEnrollment: 'El estudiante no presenta matricula',

  // Users
  userType: 'Tipo de usuario',
  school: 'Escuela',
  unit: 'Unidad',
  newUser: 'Registro de Usuario',
  deleteRegistrationUser: 'Eliminar registro de usuario',
  deleteRegistrationUserText: '¿Está seguro que desea eliminar el registro del usuario?',
  newUserInfoText: 'Complete los datos del formulario para crear un nuevo usuario',
  editUser: 'Modificar Usuario',
  identificationType: 'Tipo de documento',
  documentTypePlaceholder: 'Seleccione el tipo de documento',
  controls: 'Controles',
  graduateUnitPlaceholder: 'Seleccione una unidad de posgrado',
  deleteUnitManager: 'Eliiminar registro de usuario unidad',
  deleteSchoolManager: 'Eliminar registro de usuario escuela',
  deleteUnitManagerText: '¿Está seguro que desea eliminar el registro del usuario?',
  deleteSchoolManagerText: '¿Está seguro que desea eliminar el registro del usuario?',

  // Payments
  payments: 'Pagos',
  paymentSchedules: 'Cronograma de pensiones',
  chronograms: 'Cronogramas',
  quotaNumber: 'N° de Cuota',
  numOfPayments: 'Número de cuotas',
  interestRate: 'Interes moratorio',
  amount: 'Monto',
  dueDate: 'Fecha de Vencimiento',
  emptyPaymentSchedules: 'Todavia no hay un cronograma de pensiones registrado para el programa',
  emptyPaymentSchedulesDescription: 'Le recomendamos que registre un cronograma de pensiones para el programa y año respectivos.',
  emptyPaymentSchedulesDescriptionSchoolDirector: 'Debe esperar a que la Unidad registre un cronograma de pensiones para el programa de estudios',
  select: 'Seleccione',
  updateReport: 'Actualizar pagos',
  updatePaymentRecord: 'Actualizar registro de pagos',
  updatePaymentRecordText: 'Complete correctamente la plantilla para el registro de pagos.',
  downloadPaymentsTemplate: 'Descargar la plantilla para subir el registro de pagos.',
  lastUpdate: 'Última actualización',
  currentTotalCollection: 'Recaudación Actual',
  expectedNetCollection: 'Recaudación Neta Esperada',
  expectedGrossCollection: 'Recaudación Bruta Esperada',
  collectionPercentage: 'Porcentaje de Recaudación',
  summaryPayments: 'Resumen de Pagos',
  expectedNetCollectionInfo: 'Considerando los descuentos a los estudiantes',
  expectedGrossCollectionInfo: 'Considerando el monto total de la cuota de pago',
  collectionPercentageInfo: 'Calculado en base a la recaudación neta',
  downloadReport: 'Descargar reporte',
  emptyEntrantsByYearTitle: 'Todavía no se han registrado ingresantes para el año seleccionado.',
  emptyEntrantsByYearUnitDirector: 'Le recomendamos que espere a que el director de la escuela registre la lista de ingresantes para el año seleccionado.',
  emptyEntrantsByYearSchoolDirector: 'Le recomendamos que registre la lista de ingresantes para el año seleccionado',

  // RegisterProgram
  rgunit: 'Seleccione Unidad',
  rgcode: 'Codigo del Programa',
  rgname: 'Nombre del Programa',
  rgmention: 'Mencion del Programa',
  downloadProgramCourseTemplate: 'Descargar la plantilla para subir cursos del programa',

  //RegisterCourses
  registerCourses: 'Registrar Cursos',
  registerCourse: 'Registrar Curso',
  registerCoursesTitle: 'Registrar Cursos',
  registerCoursesInfoText: 'Cargue el archivo en formato Excel .xslx con la relacion de cursos para ser registrados en el programa',


};



export default resourceES;
