import {
  FiBook,
  FiHome, FiLayers, FiUsers, FiInbox, FiUserPlus, FiDollarSign, FiCreditCard,
} from 'react-icons/fi';
import { TFunction } from 'react-i18next';
import { AiOutlineUsergroupAdd, AiOutlineUsergroupDelete } from 'react-icons/ai';
import routesDictionary from '../../routes/routesDictionary';

export const smallBreakpoints = ['lg', 'md', 'sm', 'xs'];

const siderMenus = (t: TFunction) => [
  {
    title: t('home'),
    key: 'home',
    icon: FiHome,
    route: routesDictionary.home.route,
    moduleName: 'home',
  },
  {
    title: t('programsForUnit'),
    key: 'programsForUnit',
    icon: FiLayers,
    route: routesDictionary.programsForUnit.route,
    moduleName: 'programs',
  },
  {
    title: t('operatingPlan'),
    key: 'operatingPlan',
    icon: FiLayers,
    route: routesDictionary.allOperatingPlan.route,
    moduleName: 'operating-plan',
  },
  {
    title: t('professors'),
    key: 'professors',
    icon: FiUsers,
    route: routesDictionary.professors.route,
    moduleName: 'professors',
  },
  {
    title: t('subjects'),
    key: 'subjects',
    icon: FiBook,
    route: routesDictionary.subjects.route,
    moduleName: 'subjects',
  },
  {
    title: t('enrollments'),
    key: 'enrollmentMenu',
    children: [
      {
        title: t('enrollments'),
        key: 'enrollments',
        icon: FiUserPlus,
        route: routesDictionary.enrollments.route,
        moduleName: 'enrollments',
      },
      {
        title: t('updates'),
        key: 'enrollmentUpdate',
        icon: AiOutlineUsergroupAdd,
        route: routesDictionary.enrollmentUpdate.route,
        moduleName: 'enrollments',
      },
      {
        title: t('removes'),
        key: 'enrollmentRemoval',
        icon: AiOutlineUsergroupDelete,
        route: routesDictionary.enrollmentRemoval.route,
        moduleName: 'enrollments',
      },
    ],
    icon: FiUsers,
    moduleName: 'enrollments',
  },
  {
    title: t('entrants'),
    key: 'entrants',
    icon: FiInbox,
    route: routesDictionary.entrants.route,
    moduleName: 'entrants',
  },
  {
    title: t('studyPlans'),
    key: 'studyPlans',
    icon: FiInbox,
    route: routesDictionary.studyPlans.route,
    moduleName: 'studyPlans',
  },
  {
    title: t('myCourses'),
    key: 'studentSubjects',
    icon: FiInbox,
    route: routesDictionary.studentSubjects.route,
    moduleName: 'studentSubjects',
  },
  {
    title: t('myCourses'),
    key: 'professorCourses',
    icon: FiBook,
    route: routesDictionary.professorAllCourses.route,
    moduleName: 'professorCourses',
  },
  {
    title: t('academicGrades'),
    key: 'professorAcademicGrades',
    icon: FiBook,
    route: routesDictionary.professorAcademicGrades.route,
    moduleName: 'professorAcademicGrades',
  },
  {
    title: t('academicManagement'),
    key: 'academicManagement',
    icon: FiBook,
    route: routesDictionary.academicManagement.route,
    moduleName: 'academicManagement',
  },
  {
    title: t('users'),
    key: 'users',
    icon: FiUsers,
    route: routesDictionary.users.route,
    moduleName: 'users',
  },
  {
    title: t('payments'),
    key: 'paymentsMenu',
    children: [
      {
        title: t('chronograms'),
        key: 'paymentSchedules',
        icon: FiCreditCard,
        route: routesDictionary.paymentSchedules.route,
        moduleName: 'payments',
      },
      {
        title: t('chronograms'),
        key: 'paymentSchedules',
        icon: FiCreditCard,
        route: routesDictionary.paymentSchedules.route,
        moduleName: 'payments',
      },
      {
        title: t('payments'),
        key: 'paymentManagement',
        icon: FiCreditCard,
        route: routesDictionary.paymentManagement.route,
        moduleName: 'payments',
      },
    ],
    icon: FiDollarSign,
    moduleName: 'payments',
  },
];

export default siderMenus;
