import React from 'react';
import './styles.scss';
import { Row } from 'antd';

interface BannerProps {
    message: string,

}
const Banner:React.FC<BannerProps> = ({ message }) => (
  <Row justify="center" align="middle" className="banner">
    {message}
  </Row>
);

export default Banner;
