import React from 'react';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';
import { API_URL, AUTH_TOKEN } from '../../utils/constants';
import Layout from '../Layout';
import { getCurrentLanguage } from '../../utils/tools';

const apolloHttpLink = createUploadLink({
  uri: API_URL,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(AUTH_TOKEN);
  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : '',
      'Accept-Language': getCurrentLanguage(),
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(apolloHttpLink),
  cache: new InMemoryCache(),
});

const Apollo = () => (
  <ApolloProvider client={client}>
    <Layout />
  </ApolloProvider>
);

export default Apollo;
