import {
  PROFESSOR, SCHOOL_DIRECTOR, STUDENT, UNIT_DIRECTOR,
} from '../utils/constants';

type typePermission = {
  [key: string]: string[]
}
export const permissions: typePermission = {
  [UNIT_DIRECTOR]: ['operating-plan', 'professors', 'subjects', 'home', 'studyPlans', 'entrants', 'enrollments', 'academicManagement', 'payments'],
  [SCHOOL_DIRECTOR]: ['programs', 'operating-plan', 'professors', 'subjects', 'home', 'studyPlans', 'entrants', 'enrollments', 'academicManagement', 'users', 'payments'],
  [STUDENT]: ['home', 'studentSubjects'],
  [PROFESSOR]: ['home', 'professorCourses', 'professorAcademicGrades'],
};
export default {};
