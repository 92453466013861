import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import resourceES from './es';
import resourceEN from './en';
import { APP_LANGUAGE, LANGUAGES } from '../utils/constants';

const resources = {
  EN: {
    translation: resourceEN,
  },
  ES: {
    translation: resourceES,
  },
};

const checkUndefined = (language: any) => typeof LANGUAGES[language] === 'undefined'
        || LANGUAGES[language] === 'undefined';

let defaultLanguage;

if (typeof navigator !== 'undefined') {
  defaultLanguage = (navigator && navigator.language) ? navigator.language.toLowerCase() : '';
}

if (typeof window !== 'undefined') {
  defaultLanguage = localStorage.getItem(APP_LANGUAGE);
}

defaultLanguage = checkUndefined(defaultLanguage) ? null : defaultLanguage;

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: LANGUAGES[defaultLanguage || 'es'],
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
