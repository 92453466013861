import React, {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import {
  Col,
  Drawer,
  Layout, Menu,
} from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import './styles.scss';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import siderMenus from './constants';
import { ReactComponent as LogoUnsaIcon } from '../../media/icons/LogoUnsa.svg';
import { permissions } from '../../routes/constants';

const { Sider } = Layout;

interface SidebarProps {
  displayDrawer: boolean,
  openDrawer: boolean,
  setOpenDrawer: Dispatch<SetStateAction<boolean>>
  login: string | null | undefined
}

const Sidebar:React.FC<SidebarProps> = ({
  displayDrawer, openDrawer, setOpenDrawer, login,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [pathName, setPathName] = useState<string>('');
  const { t } = useTranslation();

  useEffect(() => {
    setPathName(`/${location.pathname.split('/')[1]}`);
  }, [location]);
  const { userType } = useSelector((state: any) => state.auth);

  const handleSelect = ({ key } : any) => {
    navigate(key, { replace: true });
  };

  const siderPermissions = siderMenus(t)
    .filter((menu) => permissions[userType]?.includes(menu.moduleName));

  if (!login) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }
  if (displayDrawer) {
    return (
      <Drawer
        placement="left"
        closable={false}
        onClose={() => setOpenDrawer(false)}
        open={openDrawer}
        key="sidebar"
        className="sidebar-container"
        width={276}
      >
        <>
          <Col className="header__logo-container">
            <LogoUnsaIcon className="header__logo" />
          </Col>
          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={[pathName]}
            onSelect={handleSelect}
          >
            {
            siderPermissions.map((menu) => (
              !menu.children ? (
                <Menu.Item key={menu.route} icon={<menu.icon />}>
                  {menu.title}
                </Menu.Item>
              ) : (
                <Menu.SubMenu key={menu.key} title={menu.title} icon={<menu.icon />}>
                  { menu.children.map((submenu) => (
                    <Menu.Item key={submenu.route} icon={<submenu.icon />}>
                      {submenu.title}
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              )
            ))
        }
          </Menu>
        </>
      </Drawer>
    );
  }
  return (
    <Sider>

      <Sider
        width={276}
        className="sidebar-container"
      >
        <Col className="header__logo-container">
          <LogoUnsaIcon className="header__logo" />
        </Col>
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[pathName]}
          onSelect={handleSelect}
        >
          {
            siderPermissions.map((menu) => (
              !menu.children ? (
                <Menu.Item key={menu.route} icon={<menu.icon />}>
                  {menu.title}
                </Menu.Item>
              ) : (
                <Menu.SubMenu key={menu.key} title={menu.title} icon={<menu.icon />}>
                  { menu.children.map((submenu) => (
                    <Menu.Item key={submenu.route} icon={<submenu.icon />}>
                      {submenu.title}
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              )
            ))
        }
        </Menu>
      </Sider>
    </Sider>
  );
};

export default Sidebar;
