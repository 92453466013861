const resourceEN = {
  applicationName: 'PROCESS MANAGEMENT SYSTEM',
  welcome: 'Welcome',
  email: 'Email',
  requiredEmail: 'Please input your username!',
  noValidEmail: 'Is not a valid email!',
  password: 'Password',
  requiredPassword: 'Please input your password!',
  login: 'Login',
  loginGoogle: 'Login with institutional account',

  english: 'English',
  spanish: 'Spanish',
  logout: 'Logout',

  operatingPlans: 'Operating plans',
  programs: 'Programs',
  of: 'of',
  items: 'items',

  // Sidebar
  home: 'Home',
  operatingPlan: 'Operating plan',
  professors: 'Professors',
  students: 'Students',
  courses: 'Courses',
  users: 'Users',

  // Programs
  programsForUnit: 'Programs & Units',
  programsAndUnit: 'Programs & Units',
  registerProgram: 'Register Program',
  registerPrograms: 'Register Programs',
  registerProgramsTitle: 'Register Programs',
  registerProgramsInfoText: 'Upload the file in Excel .xlsx format with the list of programs to be registered in the system',
  // Home
  studyPrograms: 'Study Programs',
  operatingPlanStatus: 'Operating plan status',
  graduateSchool: 'Graduate School',
  graduateUnits: 'Graduate Units',
  units: 'Units',
  operatingPlanProgress: 'Percentage of aprobbed Operating Plans',
  studyPlanUpdateRequests: 'Study Plan Update Requests',
  emptyStudyPlanUpdateRequests: 'There is no study plan updates',
  requestDate: 'Request Date',

  // Operating plan view
  code: 'Code',
  program: 'Program',
  programType: 'Program type',
  status: 'Status',
  inProgress: 'In progress',
  isApproved: 'Approved',
  sent: 'Sent',
  isObserved: 'Observed',
  number: 'Nro.',
  type: 'Type',
  actions: 'Actions',
  review: 'Review',
  send: 'Send',
  year: 'Year',
  academicYear: 'Academic Year',
  operatingPlanTemplate: 'O.P. Template',
  downloadOperatingPlanDocument: 'Download operating plan document',
  downloadOperatingPlanTemplate: 'Download operating plan template',
  downloadFile: 'Download document',
  graduateUnit: 'Graduate Unit',
  download: 'Download',
  approvalRD: 'Director Approval Resolution',

  // All operating plan view
  unitManager: 'Unit Manager',
  receptionDate: 'Reception Date',
  approvalDate: 'Approval Date',
  state: 'State',

  // Operating Plan Process
  operatingPlanProcessInfoText: 'Allow the current year operating plan to be opened to allow postgraduate units register their operating plans in the system.',
  processLetter: 'Process Letter',
  descriptiveIndex: 'Descriptive Operating Plan Index File',
  opIndex: 'Operating Plan Index',
  startDate: 'Start Date',
  endDate: 'End Date',
  openOperatingPlan: 'Open Operating Plan',
  downloadOperatingPlanIndexTemplate: 'Download operating plan index file',
  summery: 'Summery',
  approved: 'Approved',
  notApproved: 'Not Approved',
  dateOpSent: 'Date Operating PLan Sent',

  // Operating plan professors view
  firstName: 'First name',
  documentNumber: 'Document number',
  newProfessor: 'New Professor',
  lastName: 'Last name',
  documentType: 'Document type',
  documentIdentification: 'Document Identification',
  country: 'Country',
  gender: 'Gender',
  FEMALE: 'FEMALE',
  MALE: 'MALE',
  orcidId: 'ORCID-ID',
  studyPlan: 'Study Plan',
  positions: 'Other positions',
  director: 'Director',
  coodinator: 'Coordinator',
  directorAndCoordinator: 'Director and Coordinator',
  noCharges: 'No Charges',
  enabled: 'Enabled',
  assignedCourses: 'Assigned courses',
  registerProfessorList: 'Register list of teachers',
  uploadProfessorFileInfoText: 'Register operating plan teachers by uploading the document with the list of teachers',
  downloadProfessorTemplate: 'Download template for teachers registration',
  uploadProfessorList: 'Upload document of teachers list',
  downloadProfessorList: 'Download professor list',

  // Operating plan subjects view
  subjects: 'Subjects',
  subject: 'Subject',
  name: 'Name',
  credits: 'Credits',
  totalHours: 'Total hours',
  semester: 'Semester',

  // Create professor view
  search: 'Search',
  verifyDocumentNumber: 'Verify document number',
  verify: 'Verify',
  male: 'Masculino',
  female: 'Femenino',
  birthDate: 'Birthdate',
  personalInformation: 'Personal information',
  contactInformation: 'Contact information',
  phone: 'Phone',
  topDegreeLabel: 'Does not have a degree',
  universityTitleLabel: 'Does not have a university title',
  professorType: 'Professor type',
  academicData: 'Academic information',
  universityTitle: 'University title',
  topDegree: 'Top degree',
  curriculumVitae: 'Curriculum Vitae',
  degrees: 'Degrees and titles',
  investigations: 'Investigations',
  sunedu: 'SUNEDU',
  saveChanges: 'Save changes',
  guest: 'Guest',
  fromUniversity: 'From universidad',
  viewDegrees: 'View degrees',
  degreesEmpty: 'There are no academic degrees available.',
  degree: 'Degree',
  institution: 'Institution',
  constancySunedu: 'Constancy SUNEDU',
  mention: 'Mention',
  degreeDiploma: 'Degree diploma',
  professorHasBeenFound: 'A professor has been found!',
  professorHasBeenNotFound: 'A professor has not been found, please complete the data!',
  professorIsNotRegistered: 'Please, fill in the details to add the teacher!',
  professorAlredyExists: 'The professor {{firstName}} {{lastName}} {{maternalLastName}} is already registered in the study program!',

  // Edit subject view
  hours: 'Hours',
  syllabus: 'Syllabus',
  registerSyllabus: 'Register syllabus',
  groups: 'Groups',
  professor: 'Professor',
  classroom: 'Classroom',
  startEndDate: 'Start - end date',
  capacity: 'Capacity',
  group: 'Group',
  schedules: 'Schedules',
  registerSchedules: 'Register schedules',
  viewSchedules: 'View schedules',
  addGroup: 'Add group',
  schedulesEmpty: 'There are no schedules available.',
  groupsEmpty: 'There are no groups available.',
  viewSyllabus: 'View syllabus',
  missingSyllabus: 'Not registered',

  // Schedule Modal
  add: 'Add',
  cancel: 'Cancel',
  day: 'Day',
  startTime: 'Start time',
  endTime: 'End time',
  saveSchedules: 'Save schedules',
  addSchedule: 'Add schedule',

  // User groups:
  Professor: 'Professor',
  Student: 'Student',
  'Unit manager': 'Unit manager',
  'School manager': 'School manager',
  // Breadcrumb items
  'operating-plan': 'Operating plan',

  view: 'View',
  observations: 'Observations',
  viewObservations: 'View observations',
  edit: 'Edit',
  delete: 'Delete',
  createProfessor: 'Create professor',
  observationsModalText: 'Check approved sections of the operating plan or type the corresponding remarks in each section.',
  observationModalTittle: 'Operating plan observations',
  observationsPlaceholder: 'Type here your observations',
  observationsSentModalText: 'The unmarked sections are not approved or have observations.',

  sendObservations: 'Send observations',
  approve: 'Approve',
  academicProgress: 'Academic progress',
  mySubjects: 'My subjects',
  percentage: 'Percentage',
  academicGrades: 'Academic Grades',
  grades: 'Grades',
  registerGrades: 'Register Grades',
  downloadGrades: 'Download Grades',
  attendanceTooltip: 'Student attendance status is based on 4 criteria: P (Present), T (Tardy), A (Absent), and J (Justified)',
  assignGrades: 'Assign Grades',
  editGrades: 'Edit Grades',
  academicManagement: 'Academic Management',
  registerAttendanceTooltip: 'Register academic grades after course completion. Start and end dates on the table refer to the grade registration process',

  // Enrollment
  enrollments: 'Enrollments',
  enrollment: 'Enrollment',
  registerStudents: 'Register students',
  confirm: 'Confirm',
  registerProgramStudents: 'Register program students',
  registerSubjectStudents: 'Register subject students',
  registerProgramStudentsText: 'When loading a new list of enrollees, all existing registers will be removed except for those registered by re-enrollment.',
  registerSubjectStudentsText: 'For registration of students enrolled in the course, only students previously enrolled in the corresponding enrollment period will be considered.',
  confirmationMessage: 'Confirmation message',
  uploadFile: 'Upload File',
  numberGroups: 'Number of Groups',
  viewStudents: 'View students',
  assignGroups: 'Assign Groups',
  editGroups: 'Edit Groups',
  save: 'Save',
  enrollmentPeriod: 'Enrollment period',
  consolidated: 'Consolidated',
  errorAddGroupEnrollment: 'Invalid file or there are no registered groups available yet',
  period: 'Period',
  condition: 'Condition',
  enrolled: 'Enrolled',
  subjectsNumber: 'Courses Number',
  process: 'Process',
  downloadStudentsList: 'Download students list',
  studentsNum: 'Students number',
  deleteStudentListText: 'All enrollment registrations will be permanently deleted with the exception of students enrolled by re-enrollment update, this includes the removal of enrollment registrations in each subject within this period. Would you like to continue?',
  voidPeriod: 'Year or Period not selected',
  grade: 'Grade',
  emptyCourseGroupsTitle: 'No groups have been created for this course yet.',
  emptyCourseGroupsDescription: 'We recommend that you register groups for the course.',
  registerGroups: 'Register groups',

  // Entrants
  entrants: 'Entrants',
  birthday: 'Date of birth',
  registerEntrants: 'Register entrants',
  admissionProcesses: 'Admission Processes',
  admissionProcess: 'Admission Process',
  admissionYear: 'Admission Year',
  registerAdmissionProcess: 'Register admission process',
  registerAdmissionProcessInfoText: 'Please enter the year and the name of the admission process to be registered in the system, and upload the Excel .xlsx file containing the list of admitted students to be registered in the system.',
  invalidFile: 'Invalid File',
  emptyEntrantsTitle: 'There is still no admission process registered for the study program.',
  emptyEntrantsDescriptionUnitDirector: 'We recommend that you wait for the school director to register an admission process ',
  emptyEntrantsDescriptionSchoolDirector: 'We recommend that you register an admission process',

  // Study Plans
  'study-plans': 'Study Plans',
  studyPlans: 'Study Plans',
  requestUpdate: 'Request update',
  updateStudyPlan: 'Update study plan',
  summaryChanges: 'Summary changes',
  approvalResolution: 'Approval resolution',
  viewRequest: 'View request',
  accept: 'Accept',
  update: 'Update',

  // Empty Descriptions
  emptyOperatingPlanTitle: 'There is still no operating plan.',
  emptyOperatingPlanDescriptionSchoolDirector: 'We recommend starting by creating an operating plan..',
  emptyOperatingPlanProcessDescriptionSchoolDirector: 'We recommend starting by creating an operating plan in the \'Operating Plan\' section.',
  emptyOperatingPlanDescriptionUnitDirector: 'We recommend that you wait for the school director to open an operating plan.',
  studyPlanTemplate: 'Study plan template',
  downloadStudyPlanTemplate: 'Download template file to upload study plan',
  operatingPlanNotApprovedTitle: 'The operating plan is still in process or has not been approved yet',
  operatingPlanNotApprovedDescriptionUnitDirector: 'We recommend that you wait for the operating plan to be approved before being able to view the requested information.',
  operatingPlanNotApprovedDescriptionSchoolDirector: 'We recommend that you check if the unit has submitted the operating plan for approval. Once it is approved, you will be able to view the required information.',
  emptyOperatingPlanProgramsTitle: 'You do not have any courses registered in a study program yet',
  emptyOperatingPlanProgramsDescriptionStudent: '',
  emptyOperatingPlanProgramsDescriptionProfessor: 'We recommend that you wait until the courses are registered in the operating Plan.',
  emptyEnrollmentPeriodsTitle: 'Enrollments not available',
  emptyEnrollmentPeriodsDescription: 'We recommend that you wait for the school director to approve operating plan before starting the enrollment process.',

  statusTooltip: 'Se marcará como completado si la asignatura tiene registrado su sílabo y existe al menos un grupo agregado',
  homeView: 'Home View',
  attendance: 'Attendance',
  registerAcademicProgress: 'Register Academic Progress',
  viewAcademicProgress: 'View Academic Progress',
  description: 'Description',
  topic: 'Topic',
  date: 'Date',
  myCourses: 'My courses',
  academicSessions: 'Academic sessions',
  registerAttendance: 'Register attendance',
  registerAttendanceText: 'Please, register your attendance here',
  viewAttendance: 'View attendance',
  allowAttendance: 'Allow attendance',
  allowStudentAttendance: 'Allow students to record attendance',
  allowStudentAttendanceText: 'Are you sure you want to allow students to record their attendance?',

  present: 'Present',
  absent: 'Absent',
  late: 'Late',
  excusedAbsence: 'Excused absence',
  emptyAcademicSessions: 'You have not yet created the academic sessions.',
  emptyAcademicSessionsDescription: 'We recommend that you create the academic sessions by pressing the button.',
  createAcademicSessions: 'Create academic sessions',
  all: 'All',
  document: 'Document',
  sortAscending: 'Click to sort ascending',
  sortDescending: 'Click to sort descending',
  cancelSorting: 'Click to cancel sorting',
  afterUploadProfessorList: 'Do not forget to record the academic information of registered teachers',
  upload: 'Upload',
  postponementDate: 'Postponement date',
  postpone: 'Postpone',
  emptyCharacter: 'ㅤ',
  viewCourses: 'View courses',
  programCourses: 'Program courses',
  viewCourse: 'View course',
  externalCourses: 'External courses',
  coursesEmpty: 'There are no courses available.',
  bannerText: 'The deadline for registration of the operating plan has expired',
  export: 'Export',
  professorProfile: 'Professor profile',
  maternalLastName: 'Maternal last name',
  fatherLastName: 'Last name',
  dni: 'DNI',
  foreignCard: 'Foreign card',

  // Student
  myEnrollments: 'My enrollments',
  withdrawalOfEnrollment: 'Withdrawal of enrollment',
  withdrawalOfEnrollmentInfoText: 'Select the courses where you wish to withdraw your enrolment and upload the document with the respective withdrawal request. In this document, please include the proof of payment for the withdrawal of enrolment',
  withdrawalRequest: 'Withdrawal Request',
  voucher: 'Voucher',
  withdrawType: 'Withdraw Type',
  Partial: 'Partial',
  Total: 'Total',
  student: 'Student',
  removalResolution: 'Resolution Directoral',
  requestReport: 'Request Report',
  sendReport: 'Send Report',
  removalReport: 'Withdrawal Report',
  withdawalIsApprove: 'Approve or Reject enrollment withdrawal',
  reject: 'Reject',
  sendRequest: 'Sent Request',
  emptyWithdrawalTitle: 'There is still no withdrawal requests',
  emptyWithdrawalDescriptionUnitDirector: '',
  emptyWithdrawalDescriptionSchoolDirector: '',
  manualEnrollment: 'manualEnrollment',
  cui: 'CUI',
  enrollmentDocuments: 'Enrollment data',
  studyProgram: 'Study program',
  resolution: 'resolution',
  resolutionNumber: 'Num of resolution',
  numberOfGroups: 'Num of groups',
  disable: 'Disable',
  equivalentCourse: 'Equivalent Course',

  // Confirmation modal
  sendOperatingPlan: 'Send Operating Plan',
  observeOperatingPlan: 'Observe Operating Plan',
  approveOperatingPlan: 'Approve Operating Plan',
  deleteRegistrationProfessor: 'Delete Professor Registration',
  deleteAcademicProgress: 'Delete Academic Progress',
  deleteStudentProgramList: 'Delete Student List',
  disableProfessorRegistration: 'Disable professor registration',
  approveOperatingPlanText: 'Are you sure you want to approve operating plan?',
  sendOperatingPlanText: 'Are you sure you want to send operating plan to school director for approval?',
  observeOperatingPlanText: 'Are you sure you want to send observations of operating plan?',
  deleteRegistrationProfessorText: 'Are you sure you want to delete professor regitration?',
  deleteAcademicProgressText: 'Are you sure you want to delete academic progress?',
  disableProfessorRegistrationText: 'When a professor is disabled, it cannot be assigned to any subject. Would you like to continue?',
  enrollmentUpdate: 'Enrollment update',
  addUpdate: 'Add update',
  studentData: 'Student data',
  entrantCode: 'Entrant code',
  numberCourses: 'Number courses',
  numberCredits: 'Number credits',
  subjectEnrollment: 'Subjects',
  numberEnrollment: 'Number enrollment',
  addCourse: 'Add subject',
  lastEnrollmentYear: 'Last enrollment year',
  enrollmentRemoval: 'Enrollment removal',
  addRemoval: 'Add enrollment removal',
  studentHasBeenNotFoundCui: '!No se ha encontrado un estudiante, porfavor verifique que el cui ingresado sea el correcto!',
  removalType: 'Removal type',
  updates: 'Updates',
  removes: 'Removes',
  enrollmentsDelete: 'Enrollment records in the course will be deleted. Do you want to continue?',
  deleteCourseEnrollments: 'Delete course enrollments',

  // Users
  userType: 'User type',
  school: 'School',
  unit: 'Unit',
  newUser: 'New User',
  deleteRegistrationUser: 'Delete registrarion User',
  deleteRegistrationUserText: 'Are you sure you want to delete user regitration?',
  newUserInfoText: 'Please complete the form to register a new user',
  editUser: 'Edit User',
  identificationType: 'Document Type',
  documentTypePlaceholder: 'Select document type',
  controls: 'Controls',
  graduateUnitPlaceholder: 'Select graduate unit',
  deleteUnitManager: 'Delete unit manager register',
  deleteSchoolManager: 'Delete school manager register',
  deleteUnitManagerText: 'Are you sure you want to delete unit manager regitration?',
  deleteSchoolManagerText: 'Are you sure you want to delete school manager regitration?',

  // Payments
  payments: 'Payments',
  paymentSchedules: 'Payment Schedules',
  chronograms: 'Schedules',
  quotaNumber: 'Quota number',
  numOfPayments: 'Number of Payments',
  interestRate: 'Interest',
  amount: 'Amount',
  dueDate: 'Due date',
  emptyPaymentSchedules: ' There is currently no payment schedule registered for this program',
  emptyPaymentSchedulesDescription: '',
  emptyPaymentSchedulesDescriptionSchoolDirector: 'You should wait for the Unit to register the pension schedule for the study program.',
  select: 'Select a semester',
  updateReport: 'Update report',
  updatePaymentRecord: 'Update payment record',
  updatePaymentRecordText: 'Please accurately complete the template for the payment registration.',
  downloadPaymentsTemplate: 'Download the template file for uploading payment registration.',
  lastUpdate: 'Last Update',
  currentTotalCollection: 'Current Total Collection',
  expectedNetCollection: 'Expected Net Collection',
  expectedGrossCollection: 'Expected Gross Collection',
  collectionPercentage: 'Collection Percentage',
  summaryPayments: 'Summary Payments',
  expectedNetCollectionInfo: 'Considering student discounts',
  expectedGrossCollectionInfo: 'Considering the total amount of the payment fee',
  collectionPercentageInfo: 'Calculated based on net collection',
  downloadReport: 'Download Report',
  emptyEntrantsByYearTitle: 'No entrants have been registered for the selected year yet.',
  emptyEntrantsByYearUnitDirector: 'We recommend that you wait for the school director to register the list of admitted students for the selected year.',
  emptyEntrantsByYearSchoolDirector: 'We recommend that you register the list of admitted students for the selected year',


// RegisterProgram
  rgunit: 'Unit Selection',
  rgcode: 'Program Code',
  rgname: 'Program Name',
  rgmention: 'Program Mention',

  registerCourses: 'Curses Register',
  registerCourse: 'Course Register',
  registerCoursesTitle: 'Course Register',
  registerCoursesInfoText: 'Upload the file in Excel .xlsx format with the list of courses to be registered in the program',
  
};


export default resourceEN;
