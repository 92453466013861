import React from 'react';
import './styles/base.scss';
import './styles/utils.scss';
import './styles/roboto.scss';
import './styles/overwrite.scss';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import Apollo from './wrappers/Apollo';
import store from './store';
import 'antd/dist/antd.min.css';
import 'animate.css';
import i18n from './i18n';

const App = () => (
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <BrowserRouter>
        <Apollo />
      </BrowserRouter>
    </Provider>
  </I18nextProvider>
);

export default App;
