import { createSlice } from '@reduxjs/toolkit';

interface uiState {
  showProgressBar: boolean,
  breadcrumb: string[],
  showBanner: boolean,
}

const initialState: uiState = {
  showProgressBar: false,
  breadcrumb: [],
  showBanner: false,
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    displayProgressBar: (state) => {
      state.showProgressBar = true;
    },
    hideProgressBar: (state) => {
      state.showProgressBar = false;
    },
    updateBreadcrumb: (state, { payload }) => {
      state.breadcrumb = payload.text;
    },
    showBanner: (state) => {
      state.showBanner = true;
    },
    hideBanner: (state) => {
      state.showBanner = false;
    },
  },
});

export const {
  displayProgressBar, hideProgressBar, updateBreadcrumb, showBanner, hideBanner,
} = uiSlice.actions;
