export const AUTH_TOKEN = '@token';
export const API_URL = `${process.env.REACT_APP_DEV_BACKEND_URL}/graphql/`;
export const BACKEND_URL = `${process.env.REACT_APP_DEV_BACKEND_URL}`;
export const XLSX_FORMAT = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';
export const PROFESSORS_FILE = '/account/professors_file/';
export const PAYMENTS_FILE = '/payments/payments_file/';
export const PAYMENTS_TEMPLATE_FILE = '/payments/payments_template_file/';
export const LANGUAGES:{[index: string] : string} = {
  en: 'EN',
  es: 'ES',
};
export const APP_LANGUAGE = 'language';

export const UNIT_DIRECTOR = 'Unit manager';
export const SCHOOL_DIRECTOR = 'School manager';
export const PROFESSOR = 'Professor';
export const STUDENT = 'Student';

// Operating plan status
export const IN_PROGRESS = 'En curso';
export const RECEIVED = 'Recibido';
export const APPROVED = 'Aprobado';
export const OBSERVED = 'Observado';

// Enrollment status
export const ENROLLED = 'Matriculado';
export const WITHDRAWN = 'Retiro';
export const DROPPED = 'Abadono';
