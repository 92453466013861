const routesDictionary = {

  login: '/login',

  home: {
    route: '/inicio',
    breadCrumb: ['home'],
    moduleName: 'home',
  },
  programsForUnit: {
    route: '/programas',
    breadCrumb: ['programs'],
    moduleName: 'programs',
  },
  coursesForProgram: {
    route: '/programas/:studyProgramId',
    func: (studyProgramId: string | undefined) => `/programas/${studyProgramId}`,
    breadCrumb: ['programs'],
    moduleName: 'programs',
  },
  // Operating plan views
  allOperatingPlan: {
    route: '/plan-funcionamiento',
    breadCrumb: ['operatingPlan'],
    moduleName: 'operating-plan',
  },
  allOperatingPlanProgram: {
    route: '/plan-funcionamiento/:operatingPlanId',
    func: (operatingPlanId: string | undefined) => `/plan-funcionamiento/${operatingPlanId}`,
    breadCrumb: ['operatingPlan'],
    moduleName: 'operating-plan',
  },
  operatingPlanProgram: {
    route: '/plan-funcionamiento/:operatingPlanId/programa-plan-funcionamiento/:programOperatingPlanId',
    func: (operatingPlanId: string | undefined, programOperatingPlanId: string | undefined) => `/plan-funcionamiento/${operatingPlanId}/programa-plan-funcionamiento/${programOperatingPlanId}`,
    breadCrumb: ['operatingPlan'],
    moduleName: 'operating-plan',
  },

  operatingPlanProgramProfessor: {
    route: '/plan-funcionamiento/:operatingPlanId/programa-plan-funcionamiento/:programOperatingPlanId/docente/:professorRegistrationId',
    func: (operatingPlanId: string, programOperatingPlanId: string, professorRegistrationId: string) => `/plan-funcionamiento/${operatingPlanId}/programa-plan-funcionamiento/${programOperatingPlanId}/docente/${professorRegistrationId}`,
    breadCrumb: ['operatingPlan', 'professor'],
    moduleName: 'operating-plan',
  },
  operatingPlanProgramCreateProfessor: {
    route: '/plan-funcionamiento/:operatingPlanId/programa-plan-funcionamiento/:programOperatingPlanId/crear-docente',
    func: (operatingPlanId: string | undefined, programOperatingPlanId: string | undefined) => `/plan-funcionamiento/${operatingPlanId}/programa-plan-funcionamiento/${programOperatingPlanId}/crear-docente`,
    breadCrumb: ['operatingPlan', 'createProfessor'],
    moduleName: 'operating-plan',
  },
  operatingPlanProgramSubject: {
    route: '/plan-funcionamiento/:operatingPlanId/programa-plan-funcionamiento/:programOperatingPlanId/asignatura/:subjectId',
    func: (operatingPlanId: string, programOperatingPlanId: string, subjectId: string) => `/plan-funcionamiento/${operatingPlanId}/programa-plan-funcionamiento/${programOperatingPlanId}/asignatura/${subjectId}`,
    breadCrumb: ['operatingPlan', 'subject'],
    moduleName: 'operating-plan',
  },

  professors: {
    route: '/docentes',
    breadCrumb: ['professors'],
    moduleName: 'professors',
  },
  createProfessor: {
    route: '/docentes/crear-docente/:programOperatingPlanId',
    func: (programOperatingPlanId: string | undefined) => `/docentes/crear-docente/${programOperatingPlanId}`,
    breadCrumb: ['professors', 'createProfessor'],
    moduleName: 'professors',
  },
  professorDetails: {
    route: '/docentes/:professorRegistrationId',
    func: (professorRegistrationId: string | undefined) => `/docentes/${professorRegistrationId}`,
    breadCrumb: ['professors'],
    moduleName: 'professors',
  },

  subjects: {
    route: '/asignaturas',
    breadCrumb: ['subjects'],
    moduleName: 'subjects',
  },
  subjectDetails: {
    route: '/asignaturas/:subjectId',
    func: (subjectId: string | undefined) => `/asignaturas/${subjectId}`,
    breadCrumb: ['subjects'],
    moduleName: 'subjects',
  },

  enrollments: {
    route: '/matriculas',
    breadCrumb: ['enrollments'],
    moduleName: 'enrollments',
  },
  programEnrollments: {
    route: '/matriculas/:studyProgramId',
    func: (studyProgramId: string | undefined) => `/matriculas/${studyProgramId}`,
    breadCrumb: ['enrollments'],
    moduleName: 'enrollments',
  },
  programSubjectEnrollments: {
    route: '/matriculas/:studyProgramId/asignatura/:subjectId',
    func: (studyProgramId: string | undefined, subjectId: string | undefined) => `/matriculas/${studyProgramId}/asignatura/${subjectId}`,
    breadCrumb: ['enrollments'],
    moduleName: 'enrollments',
  },
  manualEnrollment: {
    route: '/matriculas/:studyProgramId/asignatura/:subjectId/manual-enrollment',
    func: (studyProgramId: string | undefined, subjectId: string | undefined) => `/matriculas/${studyProgramId}/asignatura/${subjectId}/manual-enrollment`,
    breadCrumb: ['manualEnrollment'],
    moduleName: 'enrollments',
  },

  enrollmentUpdate: {
    route: '/matricula-reactualizacion',
    breadCrumb: ['enrollmentUpdate'],
    moduleName: 'enrollments',
  },
  enrollmentUpdateStudent: {
    route: '/matricula-reactualizacion/:enrollmentUpdateId',
    func: (enrollmentUpdateId: string | undefined) => `/matricula-reactualizacion/${enrollmentUpdateId}`,
    breadCrumb: ['enrollmentUpdate'],
    moduleName: 'enrollments',
  },
  createEnrollmentUpdate: {
    route: '/matricula-reactualizacion/crear',
    breadCrumb: ['enrollmentUpdate'],
    moduleName: 'enrollments',
  },
  enrollmentRemoval: {
    route: '/matricula-retiro',
    breadCrumb: ['enrollmentRemoval'],
    moduleName: 'enrollments',
  },
  enrollmentRemovalStudent: {
    route: '/matricula-retiro/:enrollmentRemovalId',
    func: (enrollmentRemovalId: string | undefined) => `/matricula-retiro/${enrollmentRemovalId}`,
    breadCrumb: ['enrollmentRemoval'],
    moduleName: 'enrollments',
  },
  createEnrollmentRemoval: {
    route: '/matricula-retiro/crear',
    breadCrumb: ['enrollmentRemoval'],
    moduleName: 'enrollments',
  },

  entrants: {
    route: '/ingresantes',
    breadCrumb: ['entrants'],
    moduleName: 'entrants',
  },
  programEntrants: {
    route: '/ingresantes/:studyProgramId',
    func: (studyProgramId: string | undefined) => `/ingresantes/${studyProgramId}`,
    breadCrumb: ['entrants'],
    moduleName: 'entrants',
  },

  studyPlans: {
    route: '/planes-estudio',
    breadCrumb: ['studyPlans'],
    moduleName: 'studyPlans',
  },
  programStudyPlans: {
    route: '/planes-estudio/:studyProgramId',
    func: (studyProgramId: string | undefined) => `/planes-estudio/${studyProgramId}`,
    breadCrumb: ['studyPlans'],
    moduleName: 'studyPlans',
  },

  academicManagement: {
    route: '/gestion-academica',
    breadCrumb: ['academicManagement'],
    moduleName: 'academicManagement',
  },
  programSubjectAcademicManagement: {
    route: '/gestion-academica/:courseGroupId',
    func: (courseGroupId: string | undefined) => `/gestion-academica/${courseGroupId}`,
    breadCrumb: ['academicManagement'],
    moduleName: 'academicManagement',
  },
  programSubjectAttendance: {
    route: '/gestion-academica/:courseGroupId/sesiones-academicas/:academicSessionId/',
    func: (courseGroupId: string | undefined, academicSessionId: string | undefined) => `/gestion-academica/${courseGroupId}/sesiones-academicas/${academicSessionId}`,
    breadCrumb: ['academicManagement'],
    moduleName: 'academicManagement',
  },
  // Payments views
  paymentSchedules: {
    route: '/cronograma-pagos',
    breadCrumb: ['paymentSchedules'],
    moduleName: 'payments',
  },
  paymentManagement: {
    route: '/gestion-pagos',
    breadCrumb: ['paymentManagement'],
    moduleName: 'payments',
  },
  programPaymentManagement: {
    route: '/gestion-pagos/:studyProgramId',
    func: (studyProgramId: string | undefined) => `/gestion-pagos/${studyProgramId}`,
    breadCrumb: ['paymentManagement'],
    moduleName: 'payments',
  },

  users: {
    route: '/usuarios',
    breadCrumb: ['users'],
    moduleName: 'users',
  },

  // Professor views
  professorAllCourses: {
    route: '/docente-cursos',
    breadCrumb: ['mySubjects'],
    moduleName: 'professorCourses',
  },
  professorCourse: {
    route: '/docente-cursos/:coursegroupId',
    func: (coursegroupId: string | undefined) => `/docente-cursos/${coursegroupId}`,
    breadCrumb: ['mySubjects'],
    moduleName: 'professorCourses',
  },
  attendance: {
    route: '/docente-cursos/:coursegroupId/sesiones-academicas/:academicSessionId/asistencia',
    func: (coursegroupId: string | undefined, academicSessionId: string | undefined) => `/docente-cursos/${coursegroupId}/sesiones-academicas/${academicSessionId}/asistencia`,
    breadCrumb: ['mySubjects'],
    moduleName: 'professorCourses',
  },
  professorAcademicGrades: {
    route: '/notas-academicas',
    breadCrumb: ['academicGrades'],
    moduleName: 'professorAcademicGrades',
  },
  studentList: {
    route: '/notas-academicas/:coursegroupId',
    func: (coursegroupId: string | undefined) => `/notas-academicas/${coursegroupId}`,
    breadCrumb: ['academicGrades'],
    moduleName: 'professorAcademicGrades',
  },

  // Student views
  studentSubjects: {
    route: '/estudiante-cursos',
    breadCrumb: ['mySubjects'],
    moduleName: 'studentSubjects',
  },
  studentSubject: {
    route: '/estudiante-cursos/:courseGroupId',
    func: (courseGroupId: string | undefined) => `/estudiante-cursos/${courseGroupId}`,
    breadCrumb: ['mySubjects'],
    moduleName: 'studentSubjects',
  },
};

export default routesDictionary;
