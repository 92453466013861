import React from 'react';
import './styles.scss';

const Footer = () => (
  <div className="footer">

    <p>Universidad Nacional de San Agustin de Arequipa</p>
    <p>Calle San Agustín 108 - Cercado Arequipa</p>
    <p>Telf. 01 54 229864</p>

    <p>WhatsApp: 976949170</p>
    <p>Email: posgrado_informes@unsa.edu.pe</p>
    <p>Facebook:  https://www.facebook.com/unsa.posgrado/</p>

  </div>
);

export default Footer;
